import Vue from 'vue'
import App from './App.vue'
import router from './router'

import {BootstrapVue} from 'bootstrap-vue'
import Scrollspy from 'vue2-scrollspy'
import VueScrollTo from 'vue-scrollto'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Scrollspy)
Vue.use(VueScrollTo)
Vue.use(VueTelInput, {
    autoDefaultCountry: false,
    dropdownOptions: {
        showDialCodeInSelection: true,
    },
    inputOptions: {
        styleClasses: 'form-control'
    },
    mode: 'international',
    preferredCountries: [
        'MT', 'NL',
    ],
    styleClasses: 'input-group',
    validCharactersOnly: true,
})

import "@/assets/scss/style.scss";

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
